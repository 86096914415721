import { graphql, useStaticQuery, Link } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import styles from "./Hero.module.css";

function HomeHero() {
  const data = useStaticQuery(graphql`
    query HomeHeroQuery {
      allContentfulPageHome {
        edges {
          node {
            heroSectionHeading
            heroSectionSubheading
            heroSectionImage {
              fluid(maxWidth: 2000, quality: 90) {
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
            }
          }
        }
      }
    }
  `);
  const dataNode = data.allContentfulPageHome.edges[0].node;
  return (
    <div className={styles.el}>
      <div className={styles.container}>
        <h1 className={styles.heading}>
          <Link to="/about/">{dataNode.heroSectionHeading}</Link>
        </h1>
        {dataNode.heroSectionSubheading && (
          <div className={styles.subheading}>
            {dataNode.heroSectionSubheading}
          </div>
        )}
      </div>
      <div className={styles.image}>
        <Img loading="eager" fluid={dataNode.heroSectionImage.fluid} />
      </div>
    </div>
  );
}

export default HomeHero;
