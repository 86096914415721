import Button from "components/Button";
import CaseStudy from "components/CaseStudy";
import Container from "components/Container";
import Heading from "components/Heading";
import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import { MdChevronRight } from "react-icons/md";
import styles from "./CaseStudies.module.css";

function HomeCaseStudies() {
  const data = useStaticQuery(graphql`
    query HomeCaseStudiesQuery {
      allContentfulPageHome {
        edges {
          node {
            caseStudiesSectionHeading
            caseStudyEntries {
              heroSectionHeading
              client
              location
              services
              slug
              thumbnailImage {
                fluid(maxWidth: 800, quality: 90) {
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                }
              }
            }
          }
        }
      }
    }
  `);
  const dataNode = data.allContentfulPageHome.edges[0].node;
  return (
    <Container>
      <div className={styles.heading}>
        <Heading as="h2">
          <Link to="/case-studies/">{dataNode.caseStudiesSectionHeading}</Link>
        </Heading>
        <div className={styles.ctaDesktop}>
          <Button background="blue" to="/case-studies/">
            View Case Studies
            <MdChevronRight />
          </Button>
        </div>
      </div>
      <div className={styles.entries}>
        {dataNode.caseStudyEntries.map((entry, i) => (
          <CaseStudy
            key={`case-study-${i}`}
            client={entry.heroSectionHeading}
            homepage
            location={entry.location}
            services={entry.services}
            slug={entry.slug}
            thumbnailImage={entry.thumbnailImage.fluid}
          />
        ))}
      </div>
      <div className={styles.ctaMobile}>
        <Button background="blue" to="/case-studies/">
          View Case Studies
          <MdChevronRight />
        </Button>
      </div>
    </Container>
  );
}

export default HomeCaseStudies;
