import Button from "components/Button";
import Container from "components/Container";
import Heading from "components/Heading";
import Service from "components/Service";
import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import { MdChevronRight } from "react-icons/md";
import styles from "./Services.module.css";

function HomeServices() {
  const data = useStaticQuery(graphql`
    query HomeServicesQuery {
      allContentfulPageHome {
        edges {
          node {
            servicesSectionHeading
            serviceComponents {
              service
              description {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
      }
    }
  `);
  const dataNode = data.allContentfulPageHome.edges[0].node;
  return (
    <Container>
      <div className={styles.heading}>
        <Heading as="h2">
          <Link to="/services/">{dataNode.servicesSectionHeading}</Link>
        </Heading>
        <div className={styles.ctaDesktop}>
          <Button background="blue" to="/photos/">
            See completed projects
            <MdChevronRight />
          </Button>
        </div>
      </div>
      <div className={styles.services}>
        {dataNode.serviceComponents.map((service, i) => (
          <Service key={i} homepage service={service.service} to="/services/" />
        ))}
      </div>
      <div className={styles.ctaMobile}>
        <Button background="blue" to="/photos/">
          See completed projects
          <MdChevronRight />
        </Button>
      </div>
    </Container>
  );
}

export default HomeServices;
