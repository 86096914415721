import React from "react";
import { MdChevronRight } from "react-icons/md";
import styles from "./index.module.css";

function Press(props) {
  return (
    <a
      className={`${styles.el} ${props.homepage && styles.homepage}`}
      href={props.url}
      rel="noopener noreferrer"
      target="_blank"
    >
      <div>
        <div className={styles.description}>{props.description}</div>
        <div className={styles.title}>{props.heading}</div>
      </div>
      <MdChevronRight />
    </a>
  );
}

export default Press;
