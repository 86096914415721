import { Link } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { MdChevronRight } from "react-icons/md";
import styles from "./index.module.css";

function CaseStudy(props) {
  return (
    <Link
      className={`${styles.el} ${props.collection && styles.collection}`}
      to={`/case-studies/${props.slug}`}
    >
      <div className={styles.image}>
        <Img loading="eager" fluid={props.thumbnailImage} />
      </div>
      <div className={styles.info}>
        <div>
          <div className={styles.location}>{props.location}</div>
          <div className={styles.client}>{props.client}</div>
        </div>
        <MdChevronRight />
      </div>
    </Link>
  );
}

export default CaseStudy;
