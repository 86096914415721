import { Link } from "gatsby";
import React from "react";
import { MdChevronRight } from "react-icons/md";
import styles from "./index.module.css";

function Service(props) {
  const El = props.to ? Link : `div`;
  return (
    <El
      className={`${styles.el} ${props.homepage && styles.homepage}`}
      to={props.to}
    >
      <div className={styles.heading}>{props.service}</div>
      {props.description && (
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: props.description.childMarkdownRemark.html,
          }}
        />
      )}
      {props.to && <MdChevronRight />}
    </El>
  );
}

export default Service;
