import Contact from "components/Contact";
import CaseStudies from "components/Home/CaseStudies";
import Hero from "components/Home/Hero";
import Press from "components/Home/Press";
import Services from "components/Home/Services";
import Layout from "components/Layout";
import Section from "components/Section";
import SEO from "components/Seo";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";

function IndexPage() {
  const data = useStaticQuery(graphql`
    query HomeQuery {
      allContentfulPageHome {
        edges {
          node {
            pageDescription
            pageTitle
          }
        }
      }
    }
  `);
  const dataNode = data.allContentfulPageHome.edges[0].node;
  return (
    <Layout>
      <SEO
        metaDescription={dataNode.pageDescription}
        title={dataNode.pageTitle}
      />
      <Hero />
      <Section>
        <Services />
      </Section>
      <Section theme="blue">
        <CaseStudies />
      </Section>
      <Section>
        <Press />
      </Section>
      <Contact />
    </Layout>
  );
}
export default IndexPage;
