import Button from "components/Button";
import Container from "components/Container";
import Heading from "components/Heading";
import Press from "components/Press";
import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import { MdChevronRight } from "react-icons/md";
import styles from "./Press.module.css";

function HomePress() {
  const data = useStaticQuery(graphql`
    query HomePressQuery {
      allContentfulPageHome {
        edges {
          node {
            pressSectionHeading
            pressEntries {
              heading
              description
              url
            }
          }
        }
      }
    }
  `);
  const dataNode = data.allContentfulPageHome.edges[0].node;
  return (
    <Container>
      <div className={styles.heading}>
        <Heading as="h2">
          <Link to="/press/">{dataNode.pressSectionHeading}</Link>
        </Heading>
        <div className={styles.ctaDesktop}>
          <Button background="blue" to="/press/">
            Read the press
            <MdChevronRight />
          </Button>
        </div>
      </div>
      <div className={styles.entries}>
        {dataNode.pressEntries.map((entry, i) => (
          <Press
            key={`press-${i}`}
            description={entry.description}
            heading={entry.heading}
            homepage
            url={entry.url}
          />
        ))}
      </div>
      <div className={styles.ctaMobile}>
        <Button background="blue" to="/press/">
          Read the press
          <MdChevronRight />
        </Button>
      </div>
    </Container>
  );
}

export default HomePress;
